const Pricing = () => {
    return (
        <section className="py-12 sm:py-16 lg:py-20 xl:py-24">
            {/* <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-7 gap-y-12 lg:gap-x-8 xl:gap-x-16">
                    <div className="flex flex-col justify-between lg:col-span-2 xl:col-span-3">
                        <div className="flex-1">
                            <p className="text-base font-semibold text-blue-600">
                                Say hello to Landingfolio
                            </p>
                            <h2 className="mt-6 text-3xl font-semibold tracking-tight text-gray-900 lg:mt-8 sm:text-4xl lg:text-5xl">
                                Pricing that scale with business
                            </h2>
                            <p className="mt-4 text-base font-normal leading-7 text-gray-600 lg:text-lg lg:pr-24 lg:mt-6 lg:leading-8">
                                Clarity gives you the blocks & components you need to create a truly professional website, landing page
                                or
                                admin panel
                                for your SaaS.
                            </p>
                        </div>

                        <div className="pt-10 mt-10 border-t border-gray-200 lg:mt-0">
                            <p className="text-base font-semibold text-gray-900">
                                Trusted by 50k+ customers
                            </p>
                            <div className="flex items-center mt-3 space-x-2">
                                <div className="flex items-center">
                                    <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                    <svg className="w-5 h-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path
                                            d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                </div>
                                <p className="text-base font-normal text-gray-900">
                                    4.4/5
                                </p>
                                <p className="text-base font-normal text-gray-600">
                                    •
                                </p>
                                <p className="text-base font-normal text-gray-600">
                                    3,841 Reviews
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 gap-6 text-center lg:col-span-3 xl:col-span-4 sm:grid-cols-2">
                        <div className="bg-white border border-gray-200 shadow-xl rounded-2xl sm:rounded-3xl">
                            <div className="px-4 py-5 sm:px-6 sm:py-8">
                                <h3 className="text-xs font-semibold tracking-widest text-gray-900 uppercase">
                                    Pro
                                </h3>
                                <p className="mt-1 text-sm font-normal text-gray-600">
                                    Best for startups
                                </p>

                                <div className="mt-8 border border-gray-200 bg-gray-50 rounded-2xl">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="flex items-end justify-center space-x-0.5">
                                            <p className="text-5xl font-semibold tracking-tight text-gray-900">
                                                1
                                            </p>
                                            <p className="pb-1 text-xl font-semibold text-gray-900">
                                                €
                                            </p>
                                            <p className="pb-1 text-xl font-semibold text-gray-900">
                                                /mo
                                            </p>
                                        </div>

                                        <p className="mt-2 text-sm font-normal text-gray-500">
                                            Billed monthly
                                        </p>
                                    </div>
                                </div>

                                <ul className="mt-8 space-y-4 text-base font-medium text-left">
                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                        Full Access to Landingfolio
                                    </li>

                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                        100 GB Free Storage
                                    </li>

                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                        Unlimited Visitors
                                    </li>

                                    <li className="flex items-center">
                                        <svg className="w-5 h-5 mr-2 text-green-600 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                        10 Agents
                                    </li>

                                    <li className="flex items-center text-gray-400">
                                        <svg className="w-5 h-5 mr-2 text-gray-400 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        Live Chat Support
                                    </li>
                                </ul>

                                <div className="mt-8">
                                    <a href="#" title=""
                                        className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium text-white transition-all duration-200 bg-blue-600 border border-transparent rounded-xl  hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                                        role="button">
                                        Get 14 days free trial
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default Pricing;