import { Navigate } from "react-router-dom";
import Register from "../../components/Reigster/Register.component";
import { useAuth } from "../../provider/AuthProvider";

const RegisterPage = () => {
  const { authToken } = useAuth();

  if (authToken) {
    return <Navigate to="/app" replace />;
  }
  
  return <Register />;
};

export default RegisterPage;
