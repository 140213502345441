import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useState, useEffect, useRef } from 'react';
import MapSetCenter from './MapSetCenter';
import L from 'leaflet';

const MapUI = ({ center, zoom, options, setMarker }) => {
    const [currentMarker, setCurrentMarker] = useState([51.505, -0.09]);

    const handleMapClick = (e) => {
        setCurrentMarker([e.latlng.lat, e.latlng.lng]);
        setMarker({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    };

    const handleLocationFound = (e) => {
        setCurrentMarker([e.latlng.lat, e.latlng.lng]);
        setMarker({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    };

    function MapEvents() {
        useMapEvents({
            click: handleMapClick,
            locationfound: handleLocationFound,
        });
        return null;
    }

    const myIcon = L.icon({
        iconUrl: '/mapicon.png',
        iconSize: [25, 41],
        iconAnchor: [12.5, 41],
        popupAnchor: [0, -41],
    });


    useEffect(() => {
        setCurrentMarker([options.latitude, options.longitude]);
        setMarker({ latitude: options.latitude, longitude: options.longitude });
    }, [options]);


    return (
        <div>
            <MapContainer center={[options.latitude, options.longitude]} zoom={zoom} scrollWheelZoom={false}   >
                <MapSetCenter center={[currentMarker ? currentMarker[0] : center[0], currentMarker ? currentMarker[1] : center[1]]} />
                <MapEvents />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={currentMarker} icon={myIcon} >
                </Marker>
            </MapContainer>
        </div>

    )
}

export default MapUI;
