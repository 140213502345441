import React from "react";
import { WEBSITE_NAME } from "../../lib/constants";
import { Link } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
import { useForm } from "react-hook-form"

const Login = () => {
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    const { email, password } = data;

    try {
      if (!process.env.REACT_APP_API) {
        throw new Error("REACT_APP_API environment variable is not defined");
      }

      const response = await fetch(`${process.env.REACT_APP_API}/auth/me`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      if (responseData?.token) {
        login(responseData.token);
      } else {
        throw new Error("Unexpected server response");
      }
    } catch (error) {
      if (error instanceof TypeError || error.message === "Network response was not ok") {
      }
    }
  };


  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <Link className="block text-blue-600 font-semibold  text-3xl text-center" href="/">
          <span>grupo</span><span className="text-black">.coworking</span>
        </Link>
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
          <div className="max-w-sm mx-auto mt-20">

            <form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="space-y-3">
                <div>
                  <div>
                    <label for="" className="text-sm font-bold text-gray-900"> Email </label>
                    <input placeholder="Email" type="email" {...register("email")} required className={`
                                        block
                                        mt-2
                                        w-full
                                        px-2
                                        py-2
                                        overflow-hidden
                                        text-base
                                        font-normal
                                        text-gray-900
                                        placeholder-gray-600
                                        transition-all
                                        duration-200
                                        border border-gray-300
                                        caret-gray-900
                                        rounded-sm
                                        bg-gray-50
                                        focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                        font-pj
                                    `} />
                  </div>
                </div>
                <div>
                  <div>
                    <label for="" className="text-sm font-bold text-gray-900"> Contraseña </label>
                    <input
                      {...register("password")}
                      type="password"
                      required
                      placeholder="Password (min. 8 characters)"
                      className={`
                                        block
                                        w-full
                                        mt-2
                                        px-2
                                        py-2
                                        overflow-hidden
                                        text-base
                                        font-normal
                                        text-gray-900
                                        placeholder-gray-600
                                        transition-all
                                        duration-200
                                        border border-gray-300
                                        caret-gray-900
                                        rounded-sm
                                        bg-gray-50
                                        focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                        font-pj
                                    `}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-4">
                <div className="flex items-center">
                  <div className="flex items-center h-5">
                  </div>
                </div>
                {/* <a
                  href="#"
                  title=""
                  className="text-base font-medium text-gray-500 rounded hover:text-gray-900 font-pj hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                >
                  Forgot Password?
                </a> */}
              </div>
              <div className="relative mt-4">
                <button
                  name="Login"
                  type="submit"
                  className={`bg-blue-600 relative flex items-center justify-center w-full px-2 py-2 text-base font-bold text-white transition-all duration-200  border border-transparent rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600`}
                >Acceso</button>
              </div>
            </form>
            <p className="mt-4 text-base text-center text-gray-900 font-pj">
              ¿No tienes una cuenta?
              <Link
                to="/auth/register"
                title=""
                className="font-bold rounded hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2 ml-2"
              >
                Crea una cuenta
              </Link>
            </p>

          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
