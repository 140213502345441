const Input = ({ register, errors, label, type, dataName, disabled }) => {
    return (
        <div className="mb-4 mt-6">
            <input
                disabled={disabled}
                placeholder={label.charAt(0).toUpperCase() + label.slice(1)}
                type={type}
                {...register(dataName, { required: true })}
                className={`border ${errors[dataName] ? "border-red-500 text-red-500" : "border-pink text-gray-700"} rounded-sm w-full py-2 px-3`}
            />
        </div>
    )
}

export default Input;