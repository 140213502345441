


export default function Title({ children, type }) {

    switch (type) {
        case "h1":
            return (
                <h1 className="text-4xl font-normal font-serif">{children}</h1>
            )
        case "h2":
            return (
                <h2 className="text-3xl font-normal font-serif">{children}</h2>
            )
        case "h3":
            return (
                <h3 className="text-xl font-normal font-serif">{children}</h3>
            )
        case "h4":
            return (
                <h4 className="text-lg font-normal font-serif">{children}</h4>
            )
        case "h5":
            return (
                <h5 className="text-base font-normal font-serif">{children}</h5>
            )

        default:
            return (
                <h1 className="text-3xl font-normal font-serif">{children}</h1>
            )
    }



}