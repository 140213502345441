import { FaEdit, FaPlus } from "react-icons/fa";
import Button from "../../../components/UI/Button/Button.component";
import Title from "../../../components/UI/Title/Title.component";
import useFetch from "../../../hooks/useFetch";
import { Link } from "react-router-dom";

const CoworkingPage = () => {

    const { data, loading, error } = useFetch("https://api.grupocoworking.com/v1/coworkings", {
        method: "GET",
    });

    return (
        <div >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Title type="h2">Administra tus coworkings</Title>
            
            </div>
            <div>
            <Link to="/app/coworkings/create" className="mt-4 flex md:w-auto w-full">
                    <Button>
                        Dar de alta un nuevo coworking
                    </Button>
                </Link>
            </div>


            {loading && <p>Cargando...</p>}

            {error && <p>Hubo un error al cargar los coworkings</p>}

            <div className="grid  gap-4 mt-4 md:grid-cols-4 sm:grid-cols-2">
                {data?.map((coworking, index) => {
                    return (
                        <div key={index} className="flex flex-col items-center border radius-4 p-4">

                            <Link to={`/app/coworkings/${coworking.id}`} key={index}>
                                <img src="https://wearecloudworks.com/wp-content/uploads/2022/01/beneficios-coworking.jpeg" alt={coworking.name} />
                                <div className="flex justify-between w-full">
                                    <p className="mt-4">{coworking.name}</p>
                                    <FaEdit className="mt-4"/>
                                </div>
                            </Link>
                        </div>

                    )
                })}
            </div>
        </div>
    )
}

export default CoworkingPage;