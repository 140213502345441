import Input from "../../../../components/UI/Input/Input.component";
import Title from "../../../../components/UI/Title/Title.component";
import { set, useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import MapUI from "../../../../components/UI/Map/map.component";



export default function CreatePage() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [municipio, setMunicipio] = useState([]);
  const [options, setOptions] = useState({
    provinces: "A Coruña",
    municipalities: "Oroso",
    latitude: 42.99442,
    longitude: -8.434846,
  });

  const [marker, setMarker] = useState({
    latitude: 42.99442,
    longitude: -8.434846,
  });

  const { data: provincias } = useFetch('https://api.grupocoworking.com/nextjs/provincias')


  const fetchMunicipios = () => {
    const result = fetch(`https://api.grupocoworking.com/nextjs/municipios/${options.provinces}`)

    return result;
  }

  useEffect(() => {
    fetchMunicipios().then((response) => response.json()).then((data) => {
      setMunicipio(data)
      setOptions({ ...options, municipalities: data[0].municipio });
    });
  }, [options.provinces]);


  const onSubmit = (data) => {

    const { name, address, phone, email } = data;

    const result = fetch('http://192.168.92.130:3007/v1/coworking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + sessionStorage.getItem('_auth')
      },
      body: JSON.stringify({
        name,
        address,
        province: options.provinces,
        municipio: options.municipalities,
        phone,
        country: "Spain",
        email,
        latitude: marker.latitude,
        longitude: marker.longitude,
        slug: name.toLowerCase().replace(/ /g, "-"),
      }),
    });
  


};

return (
  <div className="">
    <div className="w-full max-w-md mx-auto">
      <Title type="h2" className="text-2xl mb-4">Dar de alta un nuevo coworking</Title>

      <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-md mt-12">
        <Input register={register} errors={errors} label="nombre" type="text" dataName="name" />

        <select
          className="border bg-white rounded-sm w-full py-2 px-3"
          name="province"
          id="province"
          value={options.provinces}
          onChange={(e) => setOptions({ ...options, provinces: e.target.value })}
        >
          {provincias?.map((option) => {
            return (
              <option >
                {option}
              </option>
            );
          })}
        </select>

        {options.provinces && (
          <select
            className="border bg-white rounded-sm w-full py-2 px-3 mt-6"
            name="municipality"
            id="municipality"
            value={options.municipalities}
            onChange={(e) => {
              const selectedMunicipality = municipio?.find(option => option.municipio === e.target.value);

              if (selectedMunicipality) {
                setOptions({
                  ...options,
                  municipalities: e.target.value,
                  latitude: selectedMunicipality.latitud,
                  longitude: selectedMunicipality.longitud,
                });
              }
            }}
          >
            {municipio?.map((option, index) => (
              <option key={index} value={option?.municipio}>
                {option?.municipio}
              </option>
            ))}
          </select>
        )}



        <Input register={register} errors={errors} label="dirección" type="text" dataName="address" />

        <Input register={register} errors={errors} label="teléfono" type="text" dataName="phone" />

        <Input register={register} errors={errors} label="email" type="email" dataName="email" />

        <MapUI center={options} zoom={13} options={options} marker={marker} setMarker={setMarker} />




        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6 w-full">Dar de alta</button>
      </form>
    </div>
  </div>
);
}