import { Navigate } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
import Login from "../../components/Login/Login.component";

const LoginPage = () => {
  const { authToken } = useAuth();

  if (authToken) {
    return <Navigate to="/app" replace />;
  }

  return (
    <Login />
  );
};

export default LoginPage;
