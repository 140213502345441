import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const HomePage = () => {
    const [selectedPeriod, setSelectedPeriod] = useState('12 Months');
    const data = {
        '12 Months': {
            Nuevo: [76, 85, 101, 98, 87, 0, 0, 0, 0, 76, 85, 101],
            Existente: [44, 55, 57, 56, 61, 58, 63, 0, 0, 44, 55, 57],
            Visitantes: 1500,
            Leads: 300,
        },
        '6 Months': {
            Nuevo: [76, 85, 101, 98, 87, 0],
            Existente: [44, 55, 57, 56, 61, 58],
            Visitantes: 800,
            Leads: 160,
        },
        '30 Days': {
            Nuevo: [76, 85, 101, 98],
            Existente: [44, 55, 57, 56],
            Visitantes: 200,
            Leads: 40,
        },
        '7 Days': {
            Nuevo: [76, 85, 101],
            Existente: [44, 55, 57],
            Visitantes: 50,
            Leads: 10,
        },
    };

    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
    };

    const chart4Options = {
        chart: {
            type: 'area',
            height: 350,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        series: [
            {
                name: 'Nuevo usuario',
                data: data[selectedPeriod].Nuevo,
            },
            {
                name: 'Usuario existente',
                data: data[selectedPeriod].Existente,
            },
        ],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            curve: 'smooth',
            colors: undefined,
            width: 2,
        },
        grid: {
            row: {
                opacity: 0,
            },
        },
        xaxis: {
            categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        },
        yaxis: {
            show: false,
        },
        fill: {
            type: 'solid',
            opacity: [0.05, 0],
        },
        colors: ['#2563EB', '#818CF8'],
        legend: {
            position: 'bottom',
            markers: {
                radius: 12,
                offsetX: -4,
            },
            itemMargin: {
                horizontal: 12,
                vertical: 20,
            },
        },
    };

    const pieChartOptions = {
        chart: {
            type: 'pie',
        },
        labels: ['Visitantes', 'Leads'],
        colors: ['#2563EB', '#34D399'],
        legend: {
            position: 'bottom',
        },
    };

    const pieChartData = [data[selectedPeriod].Visitantes, data[selectedPeriod].Leads];

    return (
        <div className="py-12 bg-white">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-4xl mx-auto">
                    <div className="overflow-hidden bg-white border border-gray-200">
                        <div className="px-4 pt-5 sm:px-6">
                            <div className="flex flex-wrap items-center justify-between">
                                <p className="text-base font-bold text-gray-900 lg:order-1">Tráfico de usuarios</p>

                                <button
                                    type="button"
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 shadow-sm lg:order-2 2xl:order-3 md:order-last hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    <svg className="w-4 h-4 mr-1 -ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                    </svg>
                                    Export to CSV
                                </button>

                                <nav className="flex items-center justify-center mt-4 space-x-1 2xl:order-2 lg:order-3 md:mt-0 lg:mt-4 sm:space-x-2 2xl:mt-0">
                                    {['12 Months', '6 Months', '30 Days', '7 Days'].map((period) => (
                                        <button
                                            key={period}
                                            onClick={() => handlePeriodChange(period)}
                                            className={`px-2 py-2 text-xs font-bold transition-all border ${
                                                selectedPeriod === period
                                                    ? 'text-gray-900 border-gray-900'
                                                    : 'text-gray-500 border-transparent'
                                            } sm:px-4 hover:bg-gray-100 duration-200`}
                                        >
                                            {period}
                                        </button>
                                    ))}
                                </nav>
                            </div>

                            <div id="chart4" className="mt-6">
                                <Chart options={chart4Options} series={chart4Options.series} type={chart4Options.chart.type} height={chart4Options.chart.height} />
                            </div>

                            <div id="pieChart" className="mt-6">
                                <Chart options={pieChartOptions} series={pieChartData} type="pie" height={350} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
