const Button = ({ icon, children, onClick, disabled }) => {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`cursor-pointer ${disabled ? 'opacity-40' : ''} bg-blue-600 text-white p-2 rounded-sm flex items-center `}
        >
            <p className="mr-2">{icon}</p>
            {children}
        </button>
    )
}

export default Button;