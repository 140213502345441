import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { AuthProvider, useAuth } from "./provider/AuthProvider";
import LayoutProvider from "./provider/LayoutProvider";

import LoginPage from "./pages/Login/Login.page";
import RegisterPage from "./pages/Register/Register.page";
import HomePage from "./pages/App/Home/Home.page";
import SettingsPage from "./pages/App/Settings/Settings.page";
import PricingPage from "./pages/App/Pricing/Pricing.page";
import CoworkingPage from "./pages/App/Coworking/Home.page";
import AnalyticsPage from "./pages/App/Analytics/Analytics.page";
import EditPage from "./pages/App/Coworking/Edit/Edit.page";
import CreatePage from "./pages/App/Coworking/Create/Create.page";

const PrivateRoute = ({ element }) => {
  const { authToken } = useAuth();

  return authToken ? (
    <LayoutProvider>{element}</LayoutProvider>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

function App() {
  const AuthRoutes = [
    {
      path: "/auth/login",
      element: <LoginPage />,
    },
    {
      path: "/auth/register",
      element: <RegisterPage />,
    },
  ];

  const PrivateRoutes = [
    {
      path: "/",
      element: <Navigate to="/auth/login" replace />,
    },
    {
      path: "/app",
      element: (
        <PrivateRoute path="/app" element={<HomePage />} />
      ),
    },
    {
      path: "/app/coworkings",
      element: (
        <PrivateRoute path="/app/coworkings" element={<CoworkingPage />} />
      ),
    },
    {
      path: "/app/coworkings/:id",
      element: (
        <PrivateRoute path="/app/coworkings/:id" element={<EditPage />} />
      ),
    },
    {
      path: "/app/coworkings/create",
      element: (
        <PrivateRoute path="/app/coworkings/create" element={<CreatePage />} />
      ),
    },
    {
      path: "/app/analytics",
      element: (
        <PrivateRoute path="/app/analytics" element={<AnalyticsPage />} />
      ),
    },
    {
      path: "/app/settings",
      element: (
        <PrivateRoute path="/app" element={<SettingsPage />} />
      ),
    },
    {
      path: "/app/pro",
      element: <PrivateRoute path="/app" element={<PricingPage />} />,
    },
  ];

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {AuthRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          {PrivateRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;