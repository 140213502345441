import React, { useRef, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaAlignJustify, FaBuilding, FaBurn, FaChartBar, FaDoorOpen, FaHeart, FaMoon, FaRegChartBar, FaRegUserCircle, FaSun } from "react-icons/fa";

import { WEBSITE_NAME } from "../../lib/constants";
import useFetch from "../../hooks/useFetch";
import { useAuth } from "../../provider/AuthProvider";

const MenuItem = ({ item, isActive, onClick }) => (
  <Link to={item.link} onClick={onClick} className={`${isActive ? "bg-blue-600 text-white" : ""} mt-2 font-medium flex items-center p-2 text-gray-900 rounded-sm dark:text-white dark:hover:bg-gray-700 group`}>
    {item.icon}
    <span className="ms-3">{item.name}</span>
  </Link>
);

const Navbar = ({ children }) => {
  const initialTheme = localStorage.getItem("theme") || "light";
  const [theme, setTheme] = React.useState(initialTheme);


  React.useEffect(() => {
    localStorage.setItem("theme", theme);

    const body = document.body;
    theme === "dark" ? body.classList.add("dark") : body.classList.remove("dark");
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };


  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);
  const sidebarRef = useRef(null);
  const { logout } = useAuth();
  const { data } = useFetch(process.env.REACT_APP_API + "/v1/test", {
    method: "POST"
  });

  const location = useLocation();

  const menu = [
    {
      name: "Dashboard",
      icon: <FaChartBar />,
      link: "/app",
    },
    {
      name: "Coworkings",
      icon: <FaBuilding />,
      link: "/app/coworkings",
    },
    {
      name: "Analytics",
      icon: <FaRegChartBar />,
      link: "/app/analytics",
    },
  ]

  const staticMenu = [
    {
      name: "Subscription",
      icon: <FaHeart />,
      link: "/app/pro",
    },
    {
      name: "Settings",
      icon: <FaRegUserCircle />,
      link: "/app/settings",
    },
    {
      name: "Logout",
      icon: <FaDoorOpen />,
      link: "/app/",
      onClick: logout,
    },
  ]

  const activeMainMenuItem = menu.find(item => item.link === location.pathname);
  const activeStaticMenuItem = staticMenu.find(item => item.link === location.pathname);

  const toggleMobileNav = useCallback(() => {
    setIsMobileNavOpen(!isMobileNavOpen);
  }, [isMobileNavOpen]);

  if (data)
    return (
      <div>
        <button onClick={toggleMobileNav} data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
          <span className="sr-only">Open sidebar</span>
          <FaAlignJustify />
          <span className="ml-4 text-xl text-blue-600 font-medium">grupo</span><span className="text-xl font-medium text-black">.coworking</span>
        </button>

        <aside id="default-sidebar" ref={sidebarRef} className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${!isMobileNavOpen && "-translate-x-full sm:translate-x-0"}`} aria-label="Sidebar">
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <Link to={"/app"} className="mb-12  font-medium flex items-center p-2 text-blue-600 text-xl rounded-lg dark:text-white group cursor-pointer" >
            <span className="text-blue-600">grupo</span><span className="text-black">.coworking</span>
            </Link>
            {menu.map((item, index) => (
              <div onClick={() => setIsMobileNavOpen(false)}>
                <MenuItem key={index} item={item} isActive={activeMainMenuItem?.name === item.name} />
              </div>
            ))}
            <ul className="pt-4 mt-12 space-y-2 font-medium border-t border-gray-200 dark:border-gray-700">
              {staticMenu.map((item, index) => (
                <div onClick={() => setIsMobileNavOpen(false)}>
                  <MenuItem key={index} item={item} isActive={activeStaticMenuItem?.name === item.name} onClick={item.onClick} />
                </div>
              ))}
            </ul>
            <button onClick={toggleTheme} className="mt-12 flex items-center p-2 rounded-lg focus:outline-none dark:text-white">
              {theme === "dark" ? <FaSun /> : <FaMoon />}
              <span className="ms-3">{theme === "dark" ? "Light Mode" : "Dark Mode"}</span>
            </button>
          </div>
        </aside>
        <div className="p-4 sm:ml-64" onClick={() => setIsMobileNavOpen(false)}>
          {children}
        </div>
      </div>
    );
};
export default Navbar;
