import { useEffect } from "react";
import { useMap } from "react-leaflet";

const MapSetCenter = (props) => {
    const parentMap = useMap();

    useEffect(() => {
        parentMap.panTo(props.center);
    });

    return(
        <div>
        </div>
    )

}

export default MapSetCenter;