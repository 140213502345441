import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
import { useForm } from "react-hook-form";

const Register = () => {
  const [loading, setLoading] = React.useState(false);

  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    setLoading(true);
    const { email, password, name } = data;

    if (!process.env.REACT_APP_API) {
      throw new Error("REACT_APP_API environment variable is not defined");
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API}/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          name
        }),
      });

      setLoading(false);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data) {
        login(data);
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
    }
  };


  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <Link className="block text-blue-600 font-semibold  text-3xl text-center" href="/">
          <span>grupo</span><span className="text-black">.coworking</span>
        </Link>
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl ">
          <div className="max-w-sm mx-auto mt-20">
            <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
              <div className="space-y-3">
                <div>
                  <div>
                    <label for="" className="text-sm font-bold text-gray-900"> Email </label>
                    <input placeholder="Email" type="email" {...register("email")} required className={`
                                        block
                                        mt-2
                                        w-full
                                        px-2
                                        py-2
                                        overflow-hidden
                                        text-base
                                        font-normal
                                        text-gray-900
                                        placeholder-gray-600
                                        transition-all
                                        duration-200
                                        border border-gray-300
                                        caret-gray-900
                                        rounded-sm
                                        bg-gray-50
                                        focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                        font-pj
                                    `} />
                  </div>
                </div>

                <div>
                  <div>
                    <label for="" className="text-sm font-bold text-gray-900"> Usuario </label>
                    <input
                      type="text"
                      placeholder="Ususuario"
                      {...register("name")} required
                      className={`
                                        block
                                        w-full
                                        mt-2
                                        px-2
                                        py-2
                                        overflow-hidden
                                        text-base
                                        font-normal
                                        text-gray-900
                                        placeholder-gray-600
                                        transition-all
                                        duration-200
                                        border border-gray-300
                                        caret-gray-900
                                        rounded-sm
                                        bg-gray-50
                                        focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                        font-pj
                                    `}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <label for="" className="text-sm font-bold text-gray-900"> Contraseña </label>
                    <input
                      {...register("password")}
                      type="password"
                      required
                      placeholder="Password (min. 8 characters)"
                      className={`
                                        block
                                        w-full
                                        mt-2
                                        px-2
                                        py-2
                                        overflow-hidden
                                        text-base
                                        font-normal
                                        text-gray-900
                                        placeholder-gray-600
                                        transition-all
                                        duration-200
                                        border border-gray-300
                                        caret-gray-900
                                        rounded-sm
                                        bg-gray-50
                                        focus:outline-none focus:bg-white focus:border-gray-900 focus:ring-gray-900
                                        font-pj
                                    `}
                    />
                  </div>
                </div>
              </div>

              <div className="relative flex items-center mt-4">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    name="terms"
                    id="terms"
                    className="w-5 h-5 text-gray-900 border-gray-300 rounded focus:ring-gray-900"
                  />
                </div>

                <div className="ml-3 text-base">
                  <label
                    for="terms"
                    className="font-normal text-gray-900 font-pj text-sm"
                  >
                    {" "}
                    Estoy de acuerdo con los
                    {" "}
                    <a
                      href="#"
                      title=""
                      className="font-bold rounded hover:underline text-sm focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
                    >
                      Términos y condiciones

                    </a>{" "}
                  </label>
                </div>
              </div>

              <div className="relative mt-8">
                <div className="absolute -inset-2">
                </div>

                <button name="Login" disabled={loading} type="submit"
                  className={`bg-blue-600 relative flex items-center justify-center w-full px-2 py-2 text-base font-bold text-white transition-all duration-200  border border-transparent rounded-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 font-pj hover:bg-gray-600`}
                >Registrarse</button>
              </div>
            </form>

            <p className="mt-4 text-base text-center text-gray-900 font-pj">
              ¿Ya estás registrado?
              <Link
                to="/auth/login"
                title=""
                className="ml-2 font-bold rounded hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2"
              >
                Accede
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Register;
