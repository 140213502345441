import { useState, useEffect } from "react";
import { useAuth } from "../provider/AuthProvider";

const useFetch = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { authToken, logout } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(url, {
          ...options,
          headers: {
            ...(options.headers || {}),
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            logout();
          }

          setError(`Error: ${response.statusText}`);
          setData(null);
        } else {
          const result = await response.json();
          setData(result);
          setError(null);
        }
      } catch (error) {
        setError(`Error: ${error.message}`);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    if (authToken) {
      fetchData();
    }
  }, []); 

  return { data, loading, error };
};

export default useFetch;
