import Navbar from "../components/Navbar/Navbar.component";
import { useAuth } from "./AuthProvider";

const LayoutProvider = ({ children }) => {

  const { authToken, logout } = useAuth();

  if (!authToken) {
    logout();
  }

  return <Navbar children={children} />;
};

export default LayoutProvider;
