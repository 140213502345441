import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext({
  authToken: null,
  login: () => {},
  logout: () => {},
});

const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    sessionStorage.getItem("_auth") || null
  );

  const login = (token) => {
    try {
      setAuthToken(token);
      sessionStorage.setItem("_auth", token);
    } catch (error) {
      console.error("Error storing authentication token:", error);
    }
  };

  const logout = () => {
    setAuthToken(null);
    sessionStorage.removeItem("_auth");
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
